import { brandclub_colors } from "@brandclub/common-ui";
import { styled } from "@mui/material";
import { useNavigate } from "react-router";

import {
  MOBILE_SCREEN_SIZE,
  TABLET_SCREEN_SIZE,
} from "../../../AppNavigation/constants";
import InviteFriendsDialog from "../../../StoreComponents/StoreDialogs/InviteFriendsDialog";
import { useSyncContext } from "../../../SyncProvider";
import PreviewWidgetWrapper from "../Pages/components/PreviewWidgetWrapper";
import { useUserLogin } from "../../Auth/UserLoginProvider";

interface IAction {
  icon: string;
  title: string;
  onClick?: () => void;
}

export const ACTION_LOGO_LINK_PREFIX =
  "https://media.brandclub.com/brandclub/icons/actions/";
const HomeActionContainer = styled("div")({
  width: "100%",
  padding: "20px 0 30px 0",
  // display: "flex",
  // justifyContent: "center",

  [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
  [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
    padding: "15px 0 15px 0",
  },
  ".action_container": {
    display: "grid",
    justifyContent: "center",
    gap: 20,
    ".action": {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: 20,
      [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {},
      [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
        gap: 10,
      },
      cursor: "pointer",
      transition: "transform 0.4s",
      width: "100%",
      maxWidth: 120,
      "&:hover": {
        transform: "scale(1.04) translateY(-3px)",
      },
      ".image_container": {
        maxWidth: 120,
        width: "100%",
        aspectRatio: "1/1",
        backgroundColor: brandclub_colors.blueWhite,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 8,
        img: {
          width: "60%",
          height: "60%",
          maxWidth: 62,
          maxHeight: 62,
          objectFit: "contain",
        },
      },
      ".label": {
        fontSize: 16,
        fontWeight: 600,
        [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
          fontSize: 12,
        },
      },
    },
  },
});

const HomeActionSection = () => {
  const { syncAllRetailer } = useSyncContext();
  const navigate = useNavigate();
  const { signedIn: isSignedIn } = useUserLogin();

  const actionButtons: IAction[] = [
    {
      icon: "sync-purchases.svg",
      title: "Sync",
      onClick: syncAllRetailer,
    },
    {
      icon: "accounts.svg",
      title: "Accounts",
      onClick: () => {
        navigate("/dashboard/accounts");
      },
    },
    {
      icon: "reviews.svg",
      title: "Reviews",
      onClick: () => {
        navigate("/productreviews");
      },
    },
    {
      icon: "content.svg",
      title: "Content",
      onClick: () => {
        navigate("/contentrewards");
      },
    },
    {
      icon: "surveys.svg",
      title: "Surveys",
      onClick: () => {
        navigate("/surveys");
      },
    },
    {
      icon: "affiliate.svg",
      title: "Affiliate",
      onClick: () => {
        navigate("/affiliate");
      },
    },
    {
      icon: "invite-dark.svg",
      title: "Invite",
      onClick: () => {
        InviteFriendsDialog.show();
      },
    },
    {
      icon: "join.svg",
      title: "Join",
      onClick: () => {
        navigate("/clubinvites");
      },
    },
  ];

  const actionToRender = actionButtons.filter((action) => {
    if (action.title === "Sync" || action.title === "Reviews") {
      return isSignedIn;
    }
    return true;
  });
  return (
    <PreviewWidgetWrapper backgroundColor="white">
      <HomeActionContainer
        sx={{
          ".action_container": {
            gridTemplateColumns: "repeat(auto-fit, minmax(80px, 120px))",
            padding: "0 5%",
            [`@media screen and (max-width: ${TABLET_SCREEN_SIZE}px)`]: {
              padding: "0 0",
            },
            [`@media screen and (max-width: ${MOBILE_SCREEN_SIZE}px)`]: {
              padding: "0 0",
              gridTemplateColumns: "repeat(4, 1fr)",
            },
          },
        }}
      >
        <div className="action_container">
          {actionToRender.map((action) => {
            return (
              <div
                key={action.title}
                className="action"
                onClick={action.onClick}
              >
                <div className="image_container">
                  <img
                    alt="bc_action"
                    src={ACTION_LOGO_LINK_PREFIX + action.icon}
                  />
                </div>
                <div className="label">{action.title}</div>
              </div>
            );
          })}
        </div>
      </HomeActionContainer>
    </PreviewWidgetWrapper>
  );
};

export default HomeActionSection;
