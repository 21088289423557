import ReduxStore from '../types';
import { sumAllItemQuantities } from '@/utils/misc';

export const getAllCarts = (state: ReduxStore) => {
  const cart = state.checkout.optimisticAllCarts ?? state.checkout.allCarts;
  return cart.filter((c) => c.items && c.items.length > 0);
};

export const getCartCounts = (state: ReduxStore) => {
  const carts = state.checkout.optimisticAllCarts ?? state.checkout.allCarts;
  return sumAllItemQuantities(carts);
};
