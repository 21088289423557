import { v4 as uuidv4, v5 as uuidv5 } from "uuid";
import ConfigUtils from "./ConfigUtils";

// Define UUID_NAMESPACE for uuidv5
const UUID_NAMESPACE = "29cc8a0d-747c-5f85-9ff9-f2f16636d963";

export interface WebManifest {
  userAgent: string;
  platform: string;
  browserName: string;
  browserVersion: string;
  [key: string]: any;
}

export interface PlatformManifest {
  ios?: any;
  android?: any;
  web?: WebManifest;
  detach?: {
    scheme?: string;
    [key: string]: any;
  };
  scheme?: string;
  hostUri?: string;
  developer?: string;
  [key: string]: any;
}

function getWebManifest(): WebManifest {
  const userAgent = navigator.userAgent;
  const platform = navigator.platform;
  const browserData = getBrowserDetails(userAgent);

  return {
    userAgent,
    platform,
    browserName: browserData.name,
    browserVersion: browserData.version,
  };
}

function getBrowserDetails(userAgent: string) {
  let browserName = "Unknown";
  let browserVersion = "Unknown";

  if (userAgent.includes("Chrome") && !userAgent.includes("Edge")) {
    browserName = "Chrome";
    browserVersion =
      userAgent.match(/Chrome\/(\d+\.\d+\.\d+\.\d+)/)?.[1] || "Unknown";
  } else if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
    browserName = "Safari";
    browserVersion = userAgent.match(/Version\/(\d+\.\d+)/)?.[1] || "Unknown";
  } else if (userAgent.includes("Firefox")) {
    browserName = "Firefox";
    browserVersion = userAgent.match(/Firefox\/(\d+\.\d+)/)?.[1] || "Unknown";
  } else if (userAgent.includes("Edge")) {
    browserName = "Edge";
    browserVersion = userAgent.match(/Edg\/(\d+\.\d+)/)?.[1] || "Unknown";
  } else if (userAgent.includes("Trident")) {
    browserName = "Internet Explorer";
    browserVersion = userAgent.match(/rv:(\d+\.\d+)/)?.[1] || "Unknown";
  }

  return { name: browserName, version: browserVersion };
}

/**
 * Equivalent to Expo's Platform manifest
 */
export function getBrowserPlatformManifest(): PlatformManifest {
  const manifest: PlatformManifest = {};

  if (navigator.userAgent) {
    manifest.web = getWebManifest();
    manifest.scheme = window.location.protocol.replace(":", "");
    manifest.hostUri = window.location.host;
  }

  return manifest;
}

export async function getDeviceIdAsync() {
  let uuid = uuidv4();
  const storedDeviceId = localStorage.getItem("deviceId");
  if (storedDeviceId) {
    uuid = storedDeviceId;
  } else {
    localStorage.setItem("deviceId", uuid);
  }
  return uuid;
}

// Function to get installation ID, considering web environment
export function getInstallationId(userId: string) {
  const bundleIdentifier = ConfigUtils.getWebAppId();
  const browserDetails = getBrowserDetails(navigator.userAgent);
  const installationId = uuidv5(
    `${bundleIdentifier}-${userId}-${browserDetails.name}`,
    UUID_NAMESPACE
  );
  return installationId;
}

// Mocked device information
export interface IDeviceInfo {
  deviceName?: string;
  deviceId: any;
  deviceIdDeprecated: any;
  deviceYearClass: number | null;
  isDevice: boolean;
}

// App information structure for web
export interface IAppInfo {
  appId: string;
  appOwnership: string | null;
  browserName?: string;
  debugMode: boolean;
  executionEnvironment: string;
  experienceUrl: string;
  expoRuntimeVersion: string | null;
  expoVersion: string | null;
  webViewUserAgent: string | null;
  installationIdGenerated: string;
  installationId: string;
  intentUri?: string | undefined;
  isDetached?: boolean | undefined;
  isHeadless: boolean;
  linkingUri: string;
  linkingUrl: string;
  name: string;
  nativeAppVersion: string | null;
  nativeBuildVersion: string | null;
  platform?: any;
  sessionId: string;
  statusBarHeight: number;
  systemVersion?: number | undefined;
  pushNotificationToken?: string | null;
  pushNotificationGrantStatus?: string | null;
  timezoneOffsetInHours: number;
}

const buildInstallationInfoInner = async ({
  pushNotificationToken,
  pushNotificationGrantStatus,
  userId,
}: {
  pushNotificationToken?: string | null;
  pushNotificationGrantStatus: string | null;
  userId: string;
}) => {
  const appInstallation: {
    deviceInfo: IDeviceInfo;
    appInfo: IAppInfo;
    referralInfo?: any;
  } = {
    deviceInfo: {
      deviceId: null,
      deviceIdDeprecated: null,
      deviceYearClass: null,
      isDevice: true,
    },
    appInfo: {
      appId: ConfigUtils.getWebAppId(),
      appOwnership: null,
      browserName: getBrowserDetails(navigator.userAgent).name,
      debugMode: process.env.NODE_ENV === "development",
      executionEnvironment: "browser",
      experienceUrl: window.location.href,
      expoRuntimeVersion: null,
      expoVersion: null,
      webViewUserAgent: navigator.userAgent,
      installationIdGenerated: "",
      installationId: "",
      isHeadless: false,
      linkingUri: window.location.href,
      linkingUrl: window.location.href,
      name: document.title,
      platform: "Unknown",
      nativeAppVersion: null,
      nativeBuildVersion: null,
      sessionId: uuidv4(),
      statusBarHeight: 0,
      timezoneOffsetInHours: new Date().getTimezoneOffset() / 60,
    },
  };

  const installationIdGenerated = getInstallationId(userId);
  const installationId = installationIdGenerated;
  appInstallation.deviceInfo.deviceName = navigator.userAgent;
  appInstallation.deviceInfo.deviceId = await getDeviceIdAsync();
  appInstallation.deviceInfo.deviceYearClass = null;
  appInstallation.appInfo.installationIdGenerated = installationIdGenerated;
  appInstallation.appInfo.installationId = installationId;
  appInstallation.appInfo.pushNotificationToken = pushNotificationToken;
  appInstallation.appInfo.pushNotificationGrantStatus =
    pushNotificationGrantStatus;
  appInstallation.appInfo.platform = getBrowserPlatformManifest();

  // await updateAppInstallationInfoWithBranchParams(appInstallation);
  return appInstallation;
};

export const getPushNotificationsAsync = async (): Promise<{
  token: string | null;
  grantStatus: string;
}> => {
  if (!("Notification" in window)) {
    return { token: null, grantStatus: "unsupported" };
  }

  const permission = Notification.permission;
  if (permission === "granted") {
    return { token: "browser-token", grantStatus: "granted" }; // Mock token for browser
  }

  return { token: null, grantStatus: permission };
};

export const requestPushNotificationsAsync = async () => {
  if ("Notification" in window) {
    await Notification.requestPermission();
  }
};

export const getLocationWithoutPermissionPromptAsync = async (): Promise<{
  status: string;
  providerStatus?: any;
  location?: any;
}> => {
  if (!("geolocation" in navigator)) {
    return { status: "unsupported" };
  }

  return new Promise((resolve) => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve({
          status: "granted",
          location: {
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          },
        });
      },
      () => {
        resolve({ status: "denied" });
      }
    );
  });
};

export interface IAppInstallationInfo {
  isDevice: boolean;
  appInstallationInfo?: {
    deviceInfo: IDeviceInfo;
    appInfo: IAppInfo;
    referralInfo?: any;
    locationInfo?: {
      status: string;
      providerStatus?: string | undefined;
      location?: any | undefined;
    };
  };
}

// Main function to build app installation info for web
export const buildAppInstallationInfo = async ({
  userId,
}: {
  userId: string;
}): Promise<IAppInstallationInfo> => {
  const {
    token: pushNotificationToken,
    grantStatus: pushNotificationGrantStatus,
  } = await getPushNotificationsAsync();

  const appInstallationInfo = await buildInstallationInfoInner({
    pushNotificationToken,
    pushNotificationGrantStatus,
    userId,
  });

  // await updateAppInstallationInfoWithBranchParams(appInstallationInfo);

  return { isDevice: true, appInstallationInfo };
};
