import { useCallback, useEffect, useState } from "react";
import _uniqBy from "lodash/uniqBy";
import { BrandClub } from "@brandclub/common-ui";
import {
  getUnauthedSearchBrandClubs,
  searchBrandClubsForUserV2,
} from "../../../../../api";
import { SearchResult } from "../../../../../api/types/searchResult";
import reduxApolloClient from "../../../../../redux/reduxApolloClient";
import ConfigUtils from "../../../../../utils/ConfigUtils";
import { getUserSignedInState } from "../../../../../Auth";

export const useFetchClubInvites = ({
  brandIds,
  pageSize = 25,
  autoInitFetch = true,
}: {
  brandIds?: number[];
  pageSize?: number;
  autoInitFetch?: boolean;
}) => {
  const [data, setData] = useState<BrandClub[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const fetchData = useCallback(
    async (pageNumber = 0) => {
      const { signedIn } = await getUserSignedInState();

      const RES_KEY = signedIn
        ? "SearchBrandClubsForUserV2"
        : "UnauthedSearchBrandClubs";

      const query = signedIn
        ? searchBrandClubsForUserV2
        : getUnauthedSearchBrandClubs;

      try {
        setError(null);

        const res = await reduxApolloClient.query<{
          [k: string]: SearchResult<BrandClub>;
        }>({
          query,
          variables: {
            page: { start: pageNumber, size: pageSize },
            brandIds,
            searchIntent: "InAppInvite",
          },
          fetchPolicy: "network-only",
          context: {
            uri: `${ConfigUtils.getGraphQlUri()}?id=${RES_KEY}`,
          },
        });
        const newData = res?.data?.[RES_KEY]?.items ?? [];
        setData((d) => _uniqBy([...d, ...newData], "brandclubId"));
        const newHasMore = res?.data?.[RES_KEY]?.hasMore ?? false;
        setHasMore(newHasMore);
      } catch (e) {
        if (e instanceof Error) {
          setError(e.message);
        }
        console.error(e);
      }
    },
    [brandIds, pageSize]
  );

  const fetchInit = useCallback(async () => {
    try {
      setLoading(true);
      await fetchData(0);
    } finally {
      setPage(0);
      setLoading(false);
    }
  }, [fetchData]);

  const fetchMore = useCallback(async () => {
    try {
      setLoadingMore(true);
      await fetchData((page + 1) * pageSize);
    } finally {
      setPage((p) => p + 1);
      setLoadingMore(false);
    }
  }, [fetchData, page, pageSize]);

  useEffect(() => {
    if (autoInitFetch) {
      fetchInit();
    }
  }, [autoInitFetch, fetchInit]);

  return { data, loading, error, hasMore, fetchMore, loadingMore, fetchInit };
};
